import firebase from 'firebase/app'
import { production, emulators } from '../package.json'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const productionConfig = {
	apiKey: 'AIzaSyDVgYaBpOG5kXf9jOKGONdrdcbNpFUaQvw',
	authDomain: 'nube-fnb-dev.firebaseapp.com',
	projectId: 'nube-fnb-dev',
	storageBucket: 'nube-fnb-dev.appspot.com',
	messagingSenderId: '180293029661',
	appId: '1:180293029661:web:edc1d311bf7d758ad29bf4',
	measurementId: 'G-W0NVPLC1CC',
}

const developmentConfig = {
	apiKey: "AIzaSyAGuMYRowgeOcYydlo_DmrQtJhm6zmgmzI",
	authDomain: "nube-fnb-testing.firebaseapp.com",
	projectId: "nube-fnb-testing",
	storageBucket: "nube-fnb-testing.appspot.com",
	messagingSenderId: "885931361962",
	appId: "1:885931361962:web:a73b6f61d9f0b7d7ae32d3",
	measurementId: "G-N668J8EL3Y"
}

firebase.initializeApp(production ? productionConfig : developmentConfig)

export const auth = firebase.auth()
export const db = firebase.firestore()
export const functions = firebase.app().functions('asia-east2')

if (emulators) {
	auth.useEmulator('http://localhost:9099')
	db.useEmulator('localhost', 5002)
	functions.useEmulator('localhost', 5001)
}
