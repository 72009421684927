import { firestoreAction } from 'vuexfire'
import { db } from '@/firebase'

export const saleOrderModule = {
	state: () => ({
		// 銷售訂單列表
		saleOrders: [],
	}),
	actions: {
		// 綁定銷售訂單列表
		bindAdminSaleOrders: firestoreAction(async (store, { merchantId = null } = {}) => {
			let queryRef = db.collection('saleOrders').orderBy('createdAt')
			if (merchantId) queryRef = queryRef.where('merchant.id', '==', merchantId)
			return await store.bindFirestoreRef('saleOrders', queryRef)
		}),
		// 解綁銷售訂單列表
		unAdminbindSaleOrders: firestoreAction(async store => {
			return store.unbindFirestoreRef('saleOrders')
		}),
	},
	getters: {
		// 回傳銷售訂單列表
		saleOrders: state => {
			return state.saleOrders
		},
		// 回傳指定銷售訂單
		saleOrder: state => id => {
			return state.saleOrders.find(saleOrder => saleOrder.id === id)
		}
	},
}
